<template>
  <div class="page">
    <template>
      <el-form>
        <el-form-item label="自动审核">
          <el-switch
            v-model="auto_audit"
            active-value="1"
            inactive-value="0"
            active-text="开启"
            inactive-text="关闭"
            @change="autoAuditChange($event)"
          />
        </el-form-item>
      </el-form>
    </template>
    <div v-show="auto_audit == '0'">
      <el-table :data="list" @selection-change="setSelectRows">
        <el-table-column align="center" prop="id" label="ID" width="80">
        </el-table-column>
        <el-table-column align="center" prop="name" label="优惠券名">
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="formRange"
          prop="range_of_application"
          label="适用范围"
        >
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="formType"
          prop="preferential_type"
          label="优惠券类型"
        >
        </el-table-column>
        <el-table-column align="center" label="发放时间" show-overflow-tooltip>
          <template #default="{ row }">
            <div>开始:{{ row.grant_begin_time | formatDate }}</div>
            <div>结束:{{ row.grant_end_time | formatDate }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template #default="{ row }">
            <el-button type="text" @click="handleEdit(row)"
              >查看并审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pageView">
        <el-pagination
          layout="prev, pager, next"
          :page-size="queryForm.pageSize"
          :current-page.sync="queryForm.page"
          :total="count"
          @current-change="handleCurrentChange"
        />
      </div>
      <Edit ref="Edit" @fetch-data="fetchData" />
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/theTimeStamp";
import Edit from "./components/Edit";
import {
  apiGetCouponList,
  apiGetAutoAudit,
  apiSetAutoAudit,
} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      // console.log(new Date(time))
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  components: { Edit },
  props: [],
  data() {
    return {
      auto_audit: "0",
      query_time: [],
      imgShow: true,
      selectRows: "",
      // listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        type: 0,
        operation: 1,
      },
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getAutoAuditSetting();
  },
  mounted() {},
  methods: {
    getAutoAuditSetting() {
      apiGetAutoAudit().then((res) => {
        if (res.data == "1") {
          this.auto_audit = res.data;
        }
        if (res.data == "0") {
          this.auto_audit = res.data;
          this.fetchData();
        }
      });
    },
    autoAuditChange(value) {
      apiSetAutoAudit({ auto_audit: value }).then((res) => {
        if (res.code == 200) {
          if (value == "0") {
            this.fetchData();
          }
        }
      });
    },
    formRange(row) {
      if (row.range_of_application == 1) {
        return "限定分类";
      } else if (row.range_of_application == 2) {
        return "限定商品";
      } else if (row.range_of_application == 3) {
        return "限定店铺";
      } else {
        return "通用";
      }
    },
    formType(row) {
      if (row.preferential_type == 1) {
        return "折扣";
      } else if (row.preferential_type == 2) {
        return "运费";
      } else if (row.preferential_type == 3) {
        return "赠品";
      } else {
        return "满减";
      }
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs["Edit"].showEdit(row);
      } else {
        this.$refs["Edit"].showEdit();
      }
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.page = val;
      this.fetchData();
    },
    queryData() {
      this.queryForm.page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      apiGetCouponList(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
          this.count = res.data.count;
        }
      });
      this.listLoading = false;
    },
  },
};
</script>
<style>
.page {
  background-color: #fff;
  padding: 20px;
}

.pageView {
  display: flex;
  justify-content: flex-end;
}
</style>
