<template>
  <el-dialog
    :close-on-click-modal="false"
    title="优惠券审核"
    :visible.sync="dialogFormVisible"
    width="80%"
    @close="close"
  >
    <div class="operationsmanage-container">
      <el-descriptions :column="3">
        <el-descriptions-item label="优惠券名">{{
          form.name
        }}</el-descriptions-item>
        <el-descriptions-item label="优惠券类型">
          <span v-if="form.preferential_type == 0">满减</span>
          <span v-if="form.preferential_type == 1">折扣</span>
          <span v-if="form.preferential_type == 2">运费</span>
          <span v-if="form.preferential_type == 3">赠品</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="满减规则"
          v-if="form.preferential_type === 0"
        >
          <span
            >当订单金额(非实付，不包括运费)满{{
              form.discounts.standard_money
            }}元，减{{ form.discounts.reduce_money }}元</span
          >
        </el-descriptions-item>
        <el-descriptions-item
          label="折扣规则"
          v-if="form.preferential_type === 1"
          ><span
            >除去运费后，按订单金额{{ form.discounts.discount }}%支付</span
          ></el-descriptions-item
        >
        <el-descriptions-item
          label="运费规则"
          v-if="form.preferential_type === 2"
        >
          <span v-if="form.discounts.mail_type == 1">包邮</span>
          <span v-if="form.discounts.mail_type == 2"
            >减免运费
            {{ form.discounts.breaks_money }}
            元</span
          >
        </el-descriptions-item>
        <el-descriptions-item
          label="赠品列表"
          v-if="form.preferential_type === 3"
        >
          <span v-for="(item, index) in form.discounts.gift"
            >{{ index == 0 ? "" : "、" }}{{ item.sku }}-{{ item.name }}-{{
              item.num
            }}</span
          >
        </el-descriptions-item>
        <el-descriptions-item label="发放数量">
          <span v-if="form.quantity_astrict == 0">无限制</span>
          <span v-if="form.quantity_astrict == 1">限{{ form.quantity }}张</span>
        </el-descriptions-item>
        <el-descriptions-item label="发放时间"
          >{{ form.grant_begin_time }}-{{
            form.grant_end_time
          }}</el-descriptions-item
        >
        <el-descriptions-item label="发放方式">
          <span v-if="form.grant_way == 0"
            >会员自行领取(通过App/网页等方式)</span
          >
          <span v-if="form.grant_way == 1">后台工作人员操作</span>
          <span v-if="form.grant_way == 2">生成领取代码</span>
        </el-descriptions-item>
        <el-descriptions-item label="优惠码">{{
          form.code
        }}</el-descriptions-item>
        <el-descriptions-item label="领取限制">
          <span v-if="form.get_astrict.type == 0"
            >限领{{ form.get_astrict.number }} 张</span
          >
          <span v-if="form.get_astrict.type == 1"
            >每间隔{{ form.get_astrict.time }} 小时领取{{
              form.get_astrict.number
            }}</span
          >
          <span v-if="form.get_astrict.type == 2"
            >每次使用完后可领取{{ form.get_astrict.number }}张</span
          >
        </el-descriptions-item>
        <el-descriptions-item label="使用时间">
          <span v-if="form.use_astrict == 0"
            >{{ form.use_begin_time }}-{{ form.use_end_time }}</span
          >
          <span v-if="form.use_astrict == 1"
            >领取优惠券后，有效期为{{ form.day }}天</span
          >
        </el-descriptions-item>
        <el-descriptions-item label="适用范围">
          <span v-if="form.range_of_application == 0">通用</span>
          <span v-if="form.range_of_application == 1">限定分类</span>
          <span v-if="form.range_of_application == 2">限定商品</span>
          <span v-if="form.range_of_application == 3">限定店铺</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="限定分类"
          v-if="form.range_of_application == 1"
        ></el-descriptions-item>
        <el-descriptions-item
          label="限定商品"
          v-if="form.range_of_application == 2"
        >
          <span v-for="(item, index) in s_list">
            {{ index == 0 ? "" : "、" }}{{ item.skuid }}-{{ item.name }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item
          label="限定店铺"
          v-if="form.range_of_application == 3"
        >
          。。。
        </el-descriptions-item>
      </el-descriptions>
      <el-button @click="dialogFormVisible = false" size="small"
        >取 消</el-button
      >
      <el-button type="danger" @click="save(2)" size="small">驳 回</el-button>
      <el-button type="primary" @click="save(1)" size="small">审 核</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  apiAuditCoupon,
  apiGetCategoryList,
  apiGetCouponInfo,
} from "@/request/api";
import { formatDate } from "@/utils/theTimeStamp";

export default {
  name: "Edit",
  data() {
    return {
      form_loading: false,
      list: [],
      s_list: [],
      deposit_time: [],
      effective_time: [],
      form: {},
      rules: {},
      title: "",
      limitObj: {
        num1: 1,
        num2: 1,
        num3: 1,
      },
      dialogFormVisible: false,
      valueArr: [],
    };
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      // console.log(new Date(time))
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    //获取商品分类
    getCategoryList() {
      apiGetCategoryList().then((res) => {
        if (res.code == 200) {
          this.valueArr = res.data;
        }
      });
    },
    //获取优惠券详情
    getCouponDetails(id) {
      apiGetCouponInfo({ id: id }).then((res) => {
        if (res.code == 200) {
          const tempObj = res.data;
          this.form = {
            id: tempObj.id,
            name: tempObj.name,
            preferential_type: tempObj.preferential_type, //优惠券类型
            discounts: JSON.parse(tempObj.discounts),
            quantity_astrict: tempObj.quantity_astrict, //领取限制
            quantity: tempObj.quantity, //限制优惠券领取数量
            grant_begin_time: this.formatDate(tempObj.grant_begin_time), //发放开始时间
            grant_end_time: this.formatDate(tempObj.grant_end_time), //发放结束时间
            grant_way: tempObj.grant_way, //发放方式
            code: tempObj.code, //优惠码
            get_astrict: JSON.parse(tempObj.get_astrict),
            use_astrict: tempObj.use_astrict, //使用时间0有效期内1领取后n天
            use_begin_time: this.formatDate(tempObj.use_begin_time),
            use_end_time: this.formatDate(tempObj.use_end_time),
            day: tempObj.day,
            range_of_application: tempObj.range_of_application, //适用范围
            range_json: JSON.parse(tempObj.range_json),
            is_issue: tempObj.is_issue,
            is_audit: tempObj.is_audit,
          };
          this.deposit_time = [
            this.formatDate(tempObj.grant_begin_time),
            this.formatDate(tempObj.grant_end_time),
          ];
          this.effective_time = [
            this.formatDate(tempObj.use_begin_time),
            this.formatDate(tempObj.use_end_time),
          ];
          if (tempObj.range_of_application == 2) {
            this.s_list = tempObj.sku_info;
          }
        }
      });
    },
    showEdit(row) {
      if (!row) {
      } else {
        const tempObj = Object.assign({}, row);
        this.getCouponDetails(tempObj.id);
      }
      this.dialogFormVisible = true;
    },
    close() {
      /*this.$refs['form'].resetFields()
      this.form = this.$options.data().form*/
      this.dialogFormVisible = false;
    },
    save(num) {
      apiAuditCoupon({ id: this.form.id, state: num }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.dialogFormVisible = false;
          this.$emit("fetch-data");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
